<template>
  <v-select
    v-model="innerValue"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('time.calendar.leave_type')"
    item-value="id"
    :loading="loading || fetching"
    :items="items"
    color="tertiary"
    :menu-props="{ offsetY: true, overflowY: true }"
  >
    <template #selection="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #item="{ item }">
      <v-list-item-avatar size="32" :color="item.color" class="mr-2">
        <v-icon size="20">{{ item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-05 17:31:57
   * @modify date 2021-01-05 17:31:57
   * @desc LeaveTypePicker
   */
  import gql from "graphql-tag";

  export default {
    name: "LeaveTypePicker",
    inheritAttrs: false,
    props: {
      value: [String, Object],
      loading: Boolean,
      filterItems: Function
    },
    data: vm => ({
      fetching: false,
      items: []
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    },
    methods: {
      fetchItems() {
        const options = {
          query: gql`
            query leaveTypes {
              leaveTypes {
                id
                name
                icon
                color
                weight
                applicable {
                  hasTravel
                }
              }
            }
          `,
          fetchPolicy: "no-cache"
        };
        this.fetching = true;
        this.$apollo
          .query(options)
          .then(({ data: { error, leaveTypes }, errors }) => {
            if (!error && !errors) {
              if (this.filterItems && typeof this.filterItems === "function") {
                this.items = leaveTypes.filter(this.filterItems);
              } else {
                this.items = leaveTypes || [];
              }
              if (
                this.value &&
                !this.items.some(item => (this.returnObject ? item.id === this.value.id : item.id === this.value))
              ) {
                this.$emit("input", null);
              }
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.fetching = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
